import React from "react";
import { GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";

import { TPage } from "@JohnLewisPartnership/jlfs-ui-library/dist/client/ContentfulClient";

import PreviewBanner from "@/components/preview-banner/preview-banner";
import { cms } from "../bootstrap/server";
import { renderer } from "../utils/bootstrap";

export async function getServerSideProps({
  params,
  draftMode = false,
}: GetServerSidePropsContext) {
  try {
    const page = await Promise.resolve(
      cms({ preview: !!draftMode }).getBySlug(params?.slug)
    );

    return { props: { ...page } };
  } catch (err) {
    if (err instanceof Error) {
      console.error(
        err.message,
        `: Failed to get data for slug: ${params?.slug}`
      );
    }
    // return 404 on unknown errors too ?
    return { notFound: true };
  }
}

export default function Page(params: TPage) {
  const { content } = params;
  const router = useRouter();

  return (
    <>
      {renderer(content)}
      {router.isPreview && <PreviewBanner slug={params.metadata.slug} />}
    </>
  );
}
