import styles from "./preview-banner.module.scss";

export default function PreviewBanner({ slug }: { slug: string | string[] }) {
  return (
    <div className={styles.PreviewBanner}>
      <span>You are viewing the website in Preview Mode</span>
      <a role="button" href={"/api/preview/disable?slug=" + slug}>
        Exit Preview Mode
      </a>
    </div>
  );
}
